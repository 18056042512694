import React from 'react';

const footer = () => {
    return(
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <a href="https://www.maisoninvest.com.br" target="_blank" rel="noreferrer">
                            <span className="copy">&copy; Copy - Maison Invest</span>
                        </a>
                    </div>
                    <div className="col-md-6">
                        <div className="social-icons">
                            <a href="mailto:contato@maisoninvest.com.br/" rel="noreferrer"><i className="fa fa-envelope fa-lg"></i></a>
                            <a href="https://web.whatsapp.com/send?phone=5521975912002" rel="noreferrer"><i className="fa fa-whatsapp fa-lg"></i></a>
                            <a href="https://www.linkedin.com/company/maisoninvest1" rel="noreferrer"><i className="fa fa-linkedin"></i></a>
                            <a href="https://www.instagram.com/maison.invest" rel="noreferrer"><i className="fa fa-instagram fa-lg"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <p>A Maison Invest Agentes Autônomos de Investimentos LTDA é uma empresa de agentes autônomos de investimento devidamente registrada na 
                    Comissão de Valores Mobiliários (CVM), na forma da Resolução CVM 178/2023. Maison Invest Agentes Autônomos de Investimentos LTDA atua 
                    no mercado financeiro credenciada à XP Investimentos CCTVM S/A, o que pode ser verificado através do site da CVM (www.cvm.gov.br > 
                    Informações de Regulados > Agentes Autônomos > Consulta a Informações de Agentes Autônomos > A Maison Invest Agentes Autônomos 
                    de Investimentos) ou através do site da ANCORD para escritórios credenciados a partir de outubro de 2012 (http://www.ancord.org.br > 
                    Associados > Agentes Autônomos de Investimento) ou através do site da própria XP Investimentos CCTVM S/A (www.xpi.com.br > Sobre 
                    a XP > Encontre um escritório > Selecione o estado e a cidade que deseja pesquisar > Veja a lista dos Agentes Autônomos). 
                    a forma da legislação da CVM, o Agente Autônomo de Investimento não pode administrar ou gerir o patrimônio de investidores. 
                    O Agente Autônomo é um intermediário e depende da autorização prévia do cliente para realizar operações no mercado financeiro. 
                    Esta mensagem e seus anexos contém informações confidenciais destinadas a indivíduo e propósito específicos, sendo protegida por 
                    lei. Caso você não seja a pessoa a quem foi dirigida a mensagem, deve apagá-la. É terminantemente proibida a utilização, acesso, 
                    cópia ou divulgação não autorizada das informações presentes nesta mensagem. As informações contidas nesta mensagem e em seus anexos 
                    são de responsabilidade do seu autor, não representando necessariamente ideias, opiniões, pensamentos ou qualquer forma de posicionamento 
                    por parte da Maison Invest Agentes Autônomos de Investimentos. O investimento em ações é um investimento de risco e rentabilidade 
                    passada não é garantia de rentabilidade futura. Na realização de operações com derivativos existe a possibilidade de perdas superiores 
                    aos valores investidos, podendo resultar em significativas perdas patrimoniais. Para informações e dúvidas, favor contatar seu Agente 
                    Autônomo de Investimentos. Para reclamações, favor contatar a Ouvidoria da XP Investimentos no telefone nº 0800-722-3730.</p>

            </div>
        </footer>
    );
}

export default footer;