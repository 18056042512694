import { Parallax } from "react-parallax";
import { Link } from "react-scroll";
import Navbar from '../layout/Navbar';
import Hero from '../section/hero';
import About from '../section/about';
import Duvidas from '../section/duvidas';
// import Blockquote from '../section/blockquote';
// import Whatido from '../section/whatido';
// import Gallery from '../section/gallery';
// import Resume from '../section/resume';
import OndeEstamos from '../section/ondeEstamos';
// import Blog from '../section/blog';
import Contact from '../section/contact';
import TrabalheConosco from '../section/trabalheConosco';
import Footer from '../section/footer';
import Produtos from '../section/produtos';
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from '../layout/ScrollToTop';
import Particle from '../layout/Particle';
import { createGlobalStyle } from 'styled-components';

const image1 ="./img/maison/maison-4.jpg";


const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
    
`;

function home() {
  return (
    <>
    <GlobalStyles/>
    <Preloader/>
    <div className="home">

      <header id="header-wrap">
        <Navbar />
      </header>

      {/* HERO */}
      <section id="hero-area" className="bg-bottom py-0">

        <Parallax bgImage={image1} strength={300}>
        <Particle/>
        <Hero/>
        <Link smooth spy to="about">
          <span className="mouse transition" id="fly">
              <span className="scroll"></span>
          </span>
        </Link>
        </Parallax>
      </section>

      {/* Sobre */}
      <section id="about" className="pb-0">
        <About/>
        {/*<Blockquote/>*/}
      </section>

      {/* Produtos */}
      <section id="produtos" className="pb-0 pt-0">
        <Produtos/>
      </section>

      {/* Dúvidas */}
      <section id="duvidas" className="pb-0 pt-0">
        <Duvidas/>
      </section>

      {/* Gallery */}
      <section id="trabalheConosco">
        <TrabalheConosco/>
      </section>

      {/* Gallery */}
      <section id="ondeEstamos" className="pb-0">
        <OndeEstamos/>
      </section>

      {/* Gallery
      <section id="blog" className="pb-0">
        <Blog/>
      </section> */}

      {/* contact */}
      <section id="contact" className="pb-0">
        <Contact/>
        <Footer/>
      </section>

      


      <div className="float-text">
          <div className="de_social-icons">
          <a href="www.linkedin.com/company/maisoninvest1" target="_blank" rel="noopener noreferrer">
            <span className="buton"><i className="fa fa-linkedin"></i></span>
          </a>
          <a href="https://www.instagram.com/maison.invest" target="_blank" rel="noopener noreferrer">
            <span className="button"><i className="fa fa-instagram fa-lg"></i></span>
          </a>
          </div>
          <span>Siga-me</span>
      </div>
    </div>
    <ScrollToTopBtn />
    </>
  );
}

export default home;
