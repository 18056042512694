import React from 'react';
import emailjs from 'emailjs-com';
// import { Parallax } from "react-parallax";
import AOS from 'aos';
AOS.init();

// const image1 ="./img/background/5.jpg";
const contact = () => {

    function sendEmail2(e) {

    const success = document.getElementById("success");
    const button = document.getElementById("send_message");
    const failed = document.getElementById("failed");
    e.preventDefault();

    emailjs.sendForm('service_0nb8jlf', 'template_vaimki8', e.target, 'p0Ziz1cc9npnyM0_K')
      .then((result) => {
          console.log(result.text);
          success.classList.add('show');
          button.classList.add('show');
          failed.classList.remove('show');
      }, (error) => {
          console.log(error.text);
          failed.classList.add('show');
      });
  }

    return(
        <div className="section bg-top bg-bottom py-0">
            {/* <Parallax className="py-5" bgImage={image1} strength={300}>   */}
                  <div className="py-5 position-relative">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center"
                                data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-delay="0"
                                data-aos-duration="1000"
                                data-aos-easing="ease"
                                >
                                <h2>Agende um call</h2>
                                <p>Seja muito bem-vindo, que possamos construir uma ótima história juntos. </p>
                                <div className="space-border"></div>
                            </div>
                        </div>
                        <div className="col-md-8 offset-md-2"
                            data-aos="fade-up"
                                data-aos-once="true"
                                data-aos-delay="200"
                                data-aos-duration="1000"
                                data-aos-easing="ease"
                            >
                            <div className="contact_form_wrapper">
                                <form name="contactForm" id="contact_form" className="form-border" onSubmit={sendEmail2}>
                                <div className="col-md-4 col-centered">
                                    <div className="field-set">
                                    <input type="text" name="Name" id="name" className="form-control" placeholder="Nome completo" required/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-centered">
                                    <div className="field-set">
                                    <input type="text" name="Email" id="email" className="form-control" placeholder="E-mail" required/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-centered">
                                    <div className="field-set">
                                    <input type="text" name="phone" id="phone" className="form-control" placeholder="Telefone" required/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-centered">
                                    <div className="field-set">
                                    <input type="text" name="profissao" id="profissao" className="form-control" placeholder="Profissão" required/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-centered">
                                    <div className="field-set">
                                    <input type="text" name="faixaAplicacao" id="faixaAplicacao" className="form-control" placeholder="Faixa de Aplicação" required/>
                                    </div>
                                </div>
                                <div className="col-md-4 col-centered">
                                    <div className="field-set">
                                    <input type="text" name="objetivo" id="objetivo" className="form-control" placeholder="Objetivo" required/>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <div id='success' className='hide'>Enviado com sucesso.</div>
                                    <div id='failed' className='hide'>Falha no envio.</div>
                                    <div id='submit' className="mt30">
                                    <button type='submit' id='send_message' className="btn-main">
                                        ENVIAR
                                    </button>
                                    </div>
                                </div>
                                </form>
                            </div>
                            
                            <div className='container borda-contato col-md-8'>
                                <h2 className='text-center mb-4'>CONTATO</h2>
                                <div className="row text-center">
                                    <div className='container'>
                                        <div className="d-flex justify-content-center">
                                        <table className=''>
                                        <tr>
                                            <td class="text-center">
                                            <a href="https://web.whatsapp.com/send?phone=5521975912002" target="_blank" class="link-whatsapp" rel="noopener noreferrer">
                                                <span class="button"><i class="fa fa-whatsapp fa-lg contato-icon"></i></span>
                                            </a>
                                            </td>
                                            <td class="custom-text-left">
                                            <p>+55 21 97591-2002</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">
                                            <span class="buton"><i class="fa fa-envelope fa-lg contato-icon"></i></span>
                                            </td>
                                            <td class="custom-text-left">
                                            <p>contato@maisoninvest.com.br</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">
                                            <span class="buton"><i class="fa fa-linkedin fa-lg contato-icon"></i></span>
                                            </td>
                                            <td class="custom-text-left">
                                            <p>www.linkedin.com/company/maisoninvest1</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">
                                            <span class="buton"><i class="fa fa-instagram fa-lg contato-icon"></i></span>
                                            </td>
                                            <td class="custom-text-left">
                                            <p>maison.invest</p>
                                            </td>
                                        </tr>
                                        </table>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* </Parallax> */}
        </div>
    );
}

export default contact;