import React from 'react';
import emailjs from 'emailjs-com';
import { Parallax } from "react-parallax";
import AOS from 'aos';
AOS.init();

const image1 ="./img/maison/maison-10.jpg";
const trabalheConosco = () => {

    function sendEmail(e) {

    const success = document.getElementById("success");
    const button = document.getElementById("send_message");
    const failed = document.getElementById("failed");
    e.preventDefault();

    emailjs.sendForm('service_0nb8jlf', 'template_vw3gg53', e.target, 'p0Ziz1cc9npnyM0_K')
      .then((result) => {
          console.log(result.text);
          success.classList.add('show');
          button.classList.add('show');
          failed.classList.remove('show');
      }, (error) => {
          console.log(error.text);
          failed.classList.add('show');
      });
  }

    return(
        <Parallax className="py-5" bgImage={image1} strength={300}>
        <div className="container fundo mt-5 mb-5">
			<div className="row gh-5">
				<div className="col-lg-6 mt-2 mb-2"
                    data-aos="fade-up"
                        data-aos-once="true"
                    >
                    <div className="p-4">
                        <h3 className="s_border">TRABALHE CONOSCO</h3>
                        <p className="branco">JUNTE-SE À NOSSA QUALIFICADA EQUIPE DE ESPECIALISTAS</p>
                        
                    </div>
                </div>

                <div className="col-lg-6  mt-2 mb-2" data-aos="fade-up" data-aos-once="true">
  <div className="contact_form_wrapper">
    <form name="contactForm" id="contact_form" className="form-border" onSubmit={sendEmail}>
      <div className="col-md-4 col-centered">
        <div className="field-set">
          <input type="text" name="Name" id="name" className="form-control" placeholder="Nome completo" required/>
        </div>
      </div>
      <div className="col-md-4 col-centered">
        <div className="field-set">
          <input type="text" name="Email" id="email" className="form-control" placeholder="E-mail" required/>
        </div>
      </div>
      <div className="col-md-4 col-centered">
        <div className="field-set">
          <input type="text" name="phone" id="phone" className="form-control" placeholder="Telefone" required/>
        </div>
      </div>
      <div className="col-md-4 col-centered">
        <div className="field-set">
          <input type="text" name="profissao" id="profissao" className="form-control" placeholder="Profissão" required/>
        </div>
      </div>
      <div className="col-md-4 col-centered">
        <div className="field-set">
          <input type="text" name="objetivo" id="objetivo" className="form-control" placeholder="Objetivo" required/>
        </div>
      </div>
      <div className="text-center">
        <div id='success' className='hide'>Enviado com sucesso.</div>
        <div id='failed' className='hide'>Falha no envio.</div>
        <div id='submit' className="mt30">
          <button type='submit' id='send_message' className="btn-main">
            ENVIAR
          </button>
        </div>
      </div>
    </form>
  </div>
</div>


			</div>
		</div>
        </Parallax>
    );
}

export default trabalheConosco;