import React from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AOS from 'aos';
AOS.init();

const image2 ="./img/maison/maison-mao.jpg";
const image3 ="./img/maison/maison-azul.png";
const image4 ="./img/maison/maison-5.jpg";
const image5 ="./img/maison/maison-6.jpg";
const image6 ="./img/maison/maison-7.jpg";
const image7 ="./img/maison/maison-8.jpg";

const Whatido = function() {
    /* lightbox1  - Renda Fixa*/
    const [lighbx, setlighbx] = React.useState(false);
    const handleBtnClick = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose = (): void => {
      setlighbx(!lighbx);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
    /* lightbox2 - Renda Variável*/
    const [lighbx2, setlighbx2] = React.useState(false);
    const handleBtnClick2 = (): void => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose2 = (): void => {
      setlighbx2(!lighbx2);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
    /* lightbox3 - Fundos de investimentos*/
    const [lighbx3, setlighbx3] = React.useState(false);
    const handleBtnClick3 = (): void => {
      setlighbx3(!lighbx3);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose3 = (): void => {
      setlighbx3(!lighbx3);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
    /* lightbox2 - Operações estruturadas*/
    const [lighbx4, setlighbx4] = React.useState(false);
    const handleBtnClick4 = (): void => {
      setlighbx4(!lighbx4);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "hidden";
    };
    const handleBtnClickclose4 = (): void => {
      setlighbx4(!lighbx4);
      var x = document.getElementsByTagName("BODY")[0];
      x.style.overflow = "auto";
    };
	return(         
    <div className="row">
    <div className='col-md-8 p-0'>
        <img src={image2} alt="Imagem" className='w-100'/>
    </div>
          <div className='col-md-4 produtosRetangulo d-flex align-items-center justify-content-center'>
              <div className='container'>
                  <div className="text-center produtos">
                      <h2>Produtos</h2>
                  </div>
                  <div className="rounded-rectangle" onClick={handleBtnClick}>
                      <img src={image3} alt="Ícone" className='icon-image mb-0' style={{ cursor: 'pointer' }}/> RENDA FIXA
                  </div>
                  <div className="rounded-rectangle" onClick={handleBtnClick2}>
                      <img src={image3} alt="Ícone" className='icon-image mb-0' style={{ cursor: 'pointer' }}/> RENDA VARIÁVEL
                  </div>
                  <div className="rounded-rectangle" onClick={handleBtnClick3}>
                      <img src={image3} alt="Ícone" className='icon-image mb-0' style={{ cursor: 'pointer' }}/> FUNDOS DE INVESTIMENTOS
                  </div>
                  <div className="rounded-rectangle" onClick={handleBtnClick4}>
                      <img src={image3} alt="Ícone" className='icon-image mb-0' style={{ cursor: 'pointer' }}/> OPERAÇÕES ESTRUTURADAS
                  </div>
              </div>
          </div>
      
            {/* lightbox1 - Renda Fixa*/}
            {lighbx && ( 
            <div className="LightboxGal">
              <div className="closeGal">
                  <button className="button-close" onClick={handleBtnClickclose}></button>
              </div>
              <div className="v-center w-100">
                <div className="mainLightbox container">
                  <div className="row">
                      <div className="col-md-8 offset-md-2">
                          <div className="blog-read">

                              <img alt="blogimg" src={image4} className="img-fullwidth rounded"/>
                              <h2>RENDA FIXA</h2>

                              <div className="post-text">
                                  <p>
                                    Renda Fixa é uma modalidade de investimento para quem procura segurança e retornos consistentes.
                                    Os investimentos são realizados diretamente em Títulos Públicos ou Privados de Renda Fixa. Por exemplo, 
                                    quando se compra um título, se empresta dinheiro ao emissor do papel, que pode ser um banco, uma empresa 
                                    ou o governo. Em troca, recebe-se uma remuneração após um determinado prazo com juros e/ou correção monetária.<br/> 
                                    Esses títulos podem ou não ser isentos de imposto de renda.</p>
                              </div>

                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            )}
            {/* lightbox2 - Renda Variável */}
            {lighbx2 && ( 
            <div className="LightboxGal">
              <div className="closeGal">
                  <button className="button-close" onClick={handleBtnClickclose2}></button>
              </div>
              <div className="v-center w-100">
                <div className="mainLightbox container">
                  <div className="row">
                      <div className="col-md-8 offset-md-2">
                          <div className="blog-read">

                              <img alt="blogimg" src={image5} className="img-fullwidth rounded"/>
                              <h2>RENDA VARIÁVEL</h2>

                              <div className="post-text">
                                  <p>
                                    <b>Fundo Imobiliários</b><br/>
                                    Os Fundos de Investimento Imobiliário (FII) são compostos por grupos de investidores que possuem o objetivo 
                                    de aplicar recursos em diversos tipos de investimentos imobiliários, seja no desenvolvimento de empreendimentos, 
                                    em imóveis já prontos – como edifícios comerciais, shopping centers e hospitais (Fundos de Tijolo), ou até mesmo 
                                    em títulos de dívida imobiliários (Fundos de papel).<br/>
                                    O FII investe, normalmente, em grandes imóveis, o que leva à diversificação de inquilinos e minimiza o risco de 
                                    fortes oscilações na distribuição de rendimentos.</p>
                                <p>
                                    <b>Derivativos</b><br/>
                                    Derivativos são instrumentos financeiros cujo valor depende do valor de outro ativo, conhecido como ativo subjacente. 
                                    Eles são utilizados para ajudar a gerenciar o risco financeiro ou para especular sobre as mudanças no preço do ativo 
                                    subjacente.
                                </p>
                                <p>
                                    <b>Ações/BDRs</b><br/>
                                    Ações e BDRs são investimentos de Renda Variável, através deles adiquire-se frações de empresas nacionais ou 
                                    estrangeiras. O investidor que adquire essas frações passa portanto, a poder participar das assembléias das mesmas, 
                                    tem direito areceber os proventos distribuidos, e fica exposto as oscilações de mercado portanto, essa modalidade de 
                                    investimentos envolve riscos uma vez que não há um contrato de rentabilidadae garantida, mas uma premissa de um 
                                    retorno superior à Renda Fixa no longo prazo.
                                </p>
                              </div>

                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            )}
            {/* lightbox3 - Fundos de Investimentos */}
            {lighbx3 && ( 
            <div className="LightboxGal">
              <div className="closeGal">
                  <button className="button-close" onClick={handleBtnClickclose3}></button>
              </div>
              <div className="v-center w-100">
                <div className="mainLightbox container">
                  <div className="row">
                      <div className="col-md-8 offset-md-2">
                          <div className="blog-read">

                              <img alt="blogimg" src={image6} className="img-fullwidth rounded"/>
                              <h2>FUNDOS DE INVESTIMENTOS</h2>

                              <div className="post-text">
                                <p>
                                    <b>Fundos de Renda Variável</b><br/>
                                    <b>Fundos Multimercados</b><br/>
                                    <b>Fundos Internacionais</b><br/>
                                    <b>Fundos de Renda Fixa</b><br/>
                                </p>
                                  <p>
                                    Os Fundos de Investimento são uma forma simples e fácil para investir em diversos tipos de ativos, como ações, 
                                    debêntures, títulos públicos e até investimentos internacionais. São divididos em cotas, onde cada cotista 
                                    possui um número de cotas proporcional ao valor total de seus investimentos. 
                                    Os cotistas são os donos de fundos, e contam com gestores profissionais que trabalham para que os recursos 
                                    sejam aplicados da melhor forma possível, dentro do que foi combinado na política do fundo.
                                </p>
                              </div>

                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            )}
            {/* lightbox4 - Operações Estruturadas */}
            {lighbx4 && ( 
            <div className="LightboxGal">
              <div className="closeGal">
                  <button className="button-close" onClick={handleBtnClickclose4}></button>
              </div>
              <div className="v-center w-100">
                <div className="mainLightbox container">
                  <div className="row">
                      <div className="col-md-8 offset-md-2">
                          <div className="blog-read">

                              <img alt="blogimg" src={image7} className="img-fullwidth rounded"/>
                              <h2>OPERAÇÕES ESTRUTURADAS</h2>

                              <div className="post-text">
                                <p>
                                    <b>Estrutura com Derivativos</b><br/>
                                    Proteção contra oscilações e limitações de perdas.
                                </p>
                                <p>
                                    <b>COE</b><br/>
                                    É dividido em duas modalidades: Valor Nominal Protegido (quando há garantia do valor principal investido, 
                                    caso seja levado até o vencimento) e Valor Nominal em Risco (quando há possibilidade de perda até o limite 
                                    do capital investido).
                                    O COE possui duas estruturas de remuneração: Participação (a rentabilidade da operação é determinada através 
                                    da variação do ativo subjacente, onde o investidor participa de sua performance positiva e, em alguns casos, 
                                    ganha também com a queda do ativo) e Autocallables (são estruturas de remuneração que possibilitam o 
                                    encerramento antecipado caso uma condição de mercado seja atingida).
                                </p>
                              </div>

                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            )}
        </div>
    );
}

export default Whatido;

