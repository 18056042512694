import React from 'react';
import "react-circular-progressbar/dist/styles.css";


import AOS from 'aos';
AOS.init();

const image2 ="./img/maison/mapa-maison.png";

const hero = () => {
	return(
		<div className='row'>
			<div className='col-md-1'></div>
			<div className='container col-md-5'>
				{/* <div className='col-6'> */}
					<div className="pt-5">
						<h2 className='text-center'>ONDE ESTAMOS LOCALIZADOS</h2>
						<p className='ml-5'>Rua Ator Paulo Gustavo, número 293, Sala 03<br/>Icaraí – Niterói/RJ – Brasil <br/>CEP: 24.230-053</p>
					</div>
				{/* </div> */}
			</div>
			<div className='col-md-6'>
				<img src={image2} alt="Imagem" className='w-100'/>
			</div>
		</div>
	);
}

export default hero;