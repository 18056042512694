import React from 'react';
import Typed from "react-typed";
import AOS from 'aos';
// import { Link } from "react-scroll";
//import React, { useEffect, useState } from "react";

AOS.init({
  offset: 200,
  duration: 800,
  easing: 'ease-in-out-sine',
  delay: 200,
  mirror: true
});

const hero = () => {
    return(
        <div className="v-center">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h1 className="color"
                            data-aos="fade-up"
                            data-aos-delay="0"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >Quando pensa em investir seu patrimônio,<br/>você precisa se sentir em casa!
                        </h1>
                        <div className="spacer-20"></div>
                        <div className="h5_big"
                            data-aos="fade-up"
                            data-aos-delay="300"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true"
                            >
                            <Typed
                                strings={["Liberdade", "Fraternidade" , "Rentabilidade"]}
                                typeSpeed={60}
                                backSpeed={50}
                                loop
                              />
                        </div>
                            {/*<span className="button-span-maison">
                                <a className="button-maison" href="https://www.exemplo.com" target="_blank" rel="noopener noreferrer">AGENDE UM CALL</a>
    </span>*/}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default hero;


