import React from 'react';
import "react-circular-progressbar/dist/styles.css";


import AOS from 'aos';
AOS.init();

const image2 ="./img/maison/escritorioVirtual.png";

const hero = () => {
	return(
		<div className='row'>
		<div className='col-md-6 order-md-2'>
			<h1>Sobre a Maison</h1>
			<p>Quando pensa em investir seu patrimônio, você precisa se sentir em casa. A Maison proporciona toda mordomia para você ter 
			especialistas em investimentos que te conheçam por quem você é e não por um número, conhecendo seus objetivos, perfil, 
			sua vida para que possamos dentro do leque de todas as possibilidades do mercado financeiro, lhe proporcionar as melhores 
			soluções financeiras de maneira personalizada.<br/><br/>
			Apesar da nossa sede estar na cidade de Niterói/RJ, temos clientes em todo mundo e cruzamos fronteiras para ir até você, 
			seja nos EUA, Portugal, Austrália ou onde for.<br/><br/>
			Liberdade, Fraternidade e Rentabilidade! 
			</p>
		</div>
		<div className='col-md-6 order-md-1'>
			<img src={image2} alt="Imagem" className='w-100'/>
		</div>
		</div>
	);
}

export default hero;