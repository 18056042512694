import React from 'react';
import "react-circular-progressbar/dist/styles.css";


import AOS from 'aos';
AOS.init();

const image2 ="./img/maison/maison-9.png";

const hero = () => {
	return(
		<div className='row'>
			<div className='col-md-1'></div>
			<div className='col-md-5 '>
				<div className="text-center pt-md-5">
				<h2>TIRE SUAS DÚVIDAS</h2>
				</div>
				<div className='col-12'>
				<div className='row'>
					<div className='col-12 col-md-5 duvida'>
					<p>Como abrir uma <b>conta na XP?</b></p>
					</div>
					<div className='col-12 col-md-5 duvida'>
					<p><b>Como funciona</b> a assessoria na Maison?</p>						
					</div>
					<div className='col-12 col-md-5 duvida'>
					<p><b>Quanto custa</b> para investir com a Maison?</p>            
					</div>
					<div className='col-12 col-md-5 duvida'>
					<p><b>Valor mínimo</b> para investir com a Maison?</p>            
					</div>
					<div className='col-12 col-md-5 duvida'>
					<p><b>Melhor momento</b> para começar a investir?</p>           
					</div>
					<div className='col-12 col-md-5 duvida'>
					<p><b>O que faz</b> o assessor de investimentos?</p>            
					</div>
				</div>
				</div>
			</div>
			
			<div className='col-md-6'>
				<img src={image2} alt="Imagem" className='w-100'/>
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 pergunta'>
				<p>Como abrir uma conta na XP?</p>
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 resposta'>
				<p><a className="link-maison" href="https://cadastro.xpi.com.br/passo/assessor/step1?assessor=A24367" target="_blank" rel="noopener noreferrer">
				Acesse o site da XP Investimentos, a abertura de conta é gratuita.
				</a></p>            
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 pergunta'>
				<p>Como funciona a assessoria na Maison?</p>            
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 resposta'>
				<p>Ao conversar com um de nossos assessores iremos encontrar o melhor portfólio para você. Agende uma reunião pelo link: ***.</p>           
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 pergunta'>
				<p>Quanto custa para investir com a Maison?</p>           
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 resposta'>
				<p>Nossa assessoria é 100% gratuita.</p>            
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 pergunta'>
				<p>Quanto preciso ter para começar a investir na Maison?</p>            
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 resposta'>
				<p>Não possuímos aporte inicial mínimo.</p>           
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 pergunta'>
				<p>Qual o melhor momento para começa a investir?</p>            
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 resposta'>
				<p>O mais cedo possível.</p>            
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 pergunta'>
				<p>O que faz um assessor de investimentos?</p>            
			</div>
			<div className='col-md-1'></div>
			<div className='col-md-11 resposta'>
				<p>Profissionais devidamente certificados pela ANCORD dedicados a atender cada cliente de forma personalizada com expertise em investimentos.</p>           
			</div>
			</div>

	);
}

export default hero;